:root {
  --fontFamily: 'Roboto', sans-serif;
  --headingFontFamily: 'Changa', sans-serif;
  --btnFontFamily: 'Ubuntu', sans-serif;
  --primaryColor: #712af9;
  --secondaryColor: #1ae4ff;
  --paragraphColor: #beb8cc;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --bgColor: #1a1135;
  --borderRadius: 5px;
  --transition: .5s;
  --fontSize: 16px;
  --boxShadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

body {
  margin: 0;
  padding: 0;
  bottom: auto;
  color: var(--whiteColor);
  background-color: #1a1135 !important;
  font: {
      size: var(--fontSize);
      family: var(--fontFamily);
  };
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: var(--whiteColor);
  font: {
      family: var(--headingFontFamily);
      weight: 700;
  };
}
a {
  transition: var(--transition);
  color: var(--whiteColor);
  text-decoration: none;
  outline: 0 !important;

  &:hover {
      text-decoration: none;
      color: var(--primaryColor);
  }
}
:focus {
  outline: 0 !important;
}
.d-table {
  width: 100%;
  height: 100%;

  &-cell {
      vertical-align: middle;
  }
}
img {
  max-width: 100%;
  height: auto;
}
p {
  color: var(--paragraphColor);
  font-size: var(--fontSize);
  margin-bottom: 15px;
  line-height: 1.8;

  &:last-child {
      margin-bottom: 0;
  }
}
.ptb-120 {
  padding: {
      top: 120px;
      bottom: 120px;
  };
}
.pt-120 {
  padding-top: 120px;
}
.pb-120 {
  padding-bottom: 120px;
}
.ptb-90 {
  padding: {
      top: 90px;
      bottom: 90px;
  };
}
.pt-90 {
  padding-top: 90px;
}
.pb-90 {
  padding-bottom: 90px;
}
.bg-140c2dr {
  background-color: rgba(20, 12, 45, 0.45);
}
.bg-170f32 {
  background-color: #170f32;
}
.bg-140c2d {
  background-color: #140c2d;
}
.bg-1a1135 {
  background-color: #1a1135;
}

// Default Btn
.default-btn {
z-index: 1;
border: none;
overflow: hidden;
text-align: center;
border-radius: 6px;
position: relative;
display: inline-block;
padding: 14px 30px 15px;
  color: var(--whiteColor);
  transition: var(--transition);
background: linear-gradient(135deg, #FA26CA 0%, #712AF9 100%);
  font: {
      weight: 700;
      size: var(--fontSize);
      family: var(--btnFontFamily);
  };
&::before {
  top: 0;
  right: 0;
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 6px;
  transform: scaleX(0);
  transition: var(--transition);
  background: linear-gradient(98.28deg, #36e8ff 2.11%, #38c0d2 30.92%, #305bbe 97.49%);
}
&.btn-active {
  &::before {
    top: 0;
    right: 0;
    content: "";
    width: 100%;
    z-index: -1;
    height: 100%;
    position: absolute;
    transform: scaleX(1);
    transition: var(--transition);
    background: linear-gradient(98.28deg, #36e8ff 2.11%, #38c0d2 30.92%, #305bbe 97.49%);
  }
  &::after {
    top: 0;
    right: 0;
    content: "";
    width: 100%;
    z-index: -1;
    height: 100%;
    position: absolute;
    transform: scaleX(0);
    transition: var(--transition);
    background: linear-gradient(135deg, #FA26CA 0%, #712AF9 100%);
  }
  &:hover {
    &::after {
      transform: scaleX(1);
      background: linear-gradient(135deg, #FA26CA 0%, #712AF9 100%);
    }
  }
}
&.letter-spacing {
  letter-spacing: 0.1em;
}
&:hover {
  color: var(--whiteColor);

  &::before {
    transform: scaleX(1);
  }
}
}

// Section Title
.section-title {
margin: -5px auto 50px;
text-align: center;
position: relative;
max-width: 755px;
z-index: 1;

.top-title {
  margin-bottom: 12px;
  display: inline-block;
  letter-spacing: 0.1em;
  color: var(--primaryColor);
  background: linear-gradient(90.99deg, #FDCE39 0.27%, #FD8963 30%, #ED5174 98.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font: {
    family: var(--headingFontFamily);
    weight: 600;
  };
}
h2 {
  font-size: 55px;
  margin-bottom: 0; 

  span {
    color: var(--primaryColor);
  }
}
p {
  max-width: 600px;
  margin: {
    top: 12px;
    left: auto;
    right: auto;
  };
  span {
    color: var(--primaryColor);
  }
}
&.text-start {
  max-width: 100%;
  margin: {
    left: 0;
    right: 0;
  };
  .default-btn {
    right: 0;
    bottom: 5px;
    position: absolute;
    background: transparent;

    &::before {
      transform: scale(1);
    }
    &::after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      margin: 2px;
      z-index: -1;
      border-radius: 6px;
      position: absolute;
      background-color: #140c2d;
      transition: var(--transition);
    }
    &:hover {
      &::after {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
}

// Read More
.read-more {
background-color: transparent;
transition: var(--transition);
background: linear-gradient(135deg, #FA26CA 0%, #712AF9 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
color: var(--whiteColor);
background-clip: text;
display: inline-block;
letter-spacing: 0.1em;
position: relative;
z-index: 1;
  font: {
      family: var(--headingFontFamily);
      weight: 600;
  };
&::before {
  right: 0;
  width: 0;
  bottom: 0;
  content: "";
  height: 2px;
  position: absolute;
  transition: var(--transition);
  background: linear-gradient(135deg, #FA26CA 0%, #712AF9 100%);
}
&:hover {
  color: var(--primaryColor);

  &:hover {
    &::before {
      right: auto;
      width: 100%;
      left: 0;
    }
  }
}
}
.read-more-btn {
margin-top: 30px;
text-align: center;

.read-more {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: linear-gradient(90.28deg, #FDCE39 0.92%, #FD8963 47.41%, #ED5174 99.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: inline-block;
  font: {
    weight: 600;
    size: 20px;
  };
  &::before {
    display: none;
  }
}
}

// How It Works Tabs
.how-it-works-tabs {
.nav-tabset {
  padding-left: 0;
  text-align: center;
  margin-bottom: 50px;
  list-style-type: none;

  .nav-tab {
    display: inline-block;
    margin: {
      left: 5px;
      right: 5px;
    };
    span {
      z-index: 1;
      display: block;
      cursor: pointer;
      position: relative;
      padding: 28px 90px;
      border-radius: 10px;
      font: {
        size: 20px;
        weight: 500;
        family: var(--headingFontFamily);
      };
      &::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        content: '';
        z-index: -1;
        position: absolute;
        border-radius: 10px;
        transition: var(--transition);
        background: linear-gradient(180deg, #FDCE39 0.27%, #FD8963 30%, #ED5174 98.69%);
      }
      &::after {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: -1;
        margin: 2px;
        position: absolute;
        border-radius: 10px;
        background-color: #0F0824;
        transition: var(--transition);
      }
    }
    &.active {
      span {
        color: var(--whiteColor);

        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
}

// Ecosystem Tabs
.ecosystem-tabs {
.nav-tabset {
  padding-left: 0;
  margin-bottom: 50px;
  list-style-type: none;

  .nav-tab {
    display: inline-block;
    margin-right: 10px;

    span {
      z-index: 1;
      display: block;
      cursor: pointer;
      position: relative;
      padding: 15px 25px;
      border-radius: 10px;
      font: {
        size: 20px;
        weight: 500;
        family: var(--headingFontFamily);
      };
      &::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        content: '';
        z-index: -1;
        visibility: hidden;
        position: absolute;
        border-radius: 10px;
        transition: var(--transition);
        background: linear-gradient(180deg, #FDCE39 0.27%, #FD8963 30%, #ED5174 98.69%);
      }
      &::after {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: -1;
        margin: 2px;
        position: absolute;
        border-radius: 10px;
        background-color: #0F0824;
        transition: var(--transition);
      }
    }
    &.active {
      span {
        color: var(--whiteColor);

        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
}

// Projects Tabs
.projects-tabs {
.nav-tabset {
  list-style-type: none;
  background: #20183a;
  border-radius: 10px;
  margin-bottom: 50px;
  text-align: center;
  padding: 10px;

  .nav-tab {
    display: inline-block;
    margin: {
      left: 10px;
      right: 10px;
    };
    span {
      z-index: 1;
      display: block;
      cursor: pointer;
      position: relative;
      padding: 13px 15px;
      text-transform: uppercase;
      color: var(--paragraphColor);
      transition: var(--transition);
      border-radius: var(--borderRadius);
      font: {
        family: var(--headingFontFamily);
        weight: 600;
      };
      &::before {
        top: 0;
        right: 0;
        width: 0;
        content: "";
        z-index: -1;
        height: 100%;
        position: absolute;
        transition: var(--transition);
        border-radius: var(--borderRadius);
        background: linear-gradient(95.72deg, #FDCE39 2.22%, #FD8963 54.28%, #ED5174 98.56%);
      }
      &:hover {
        color: var(--whiteColor);

        &::before {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }
    &.active {
      span {
        color: var(--whiteColor);

        &::before {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }
  }
}
}
.projects-wrap-tabs {
.nav-tabset {
  list-style-type: none;
  background: #20183a;
  border-radius: 10px;
  margin-bottom: 50px;
  padding: 10px;

  .nav-tab {
    display: inline-block;
    margin: {
      left: 10px;
      right: 10px;
    };
    span {
      z-index: 1;
      display: block;
      cursor: pointer;
      position: relative;
      padding: 13px 15px;
      text-transform: uppercase;
      color: var(--paragraphColor);
      transition: var(--transition);
      border-radius: var(--borderRadius);
      font: {
        family: var(--headingFontFamily);
        weight: 600;
      };
      &::before {
        top: 0;
        right: 0;
        width: 0;
        content: "";
        z-index: -1;
        height: 100%;
        position: absolute;
        transition: var(--transition);
        border-radius: var(--borderRadius);
        background: linear-gradient(95.72deg, #FDCE39 2.22%, #FD8963 54.28%, #ED5174 98.56%);
      }
      &:hover {
        color: var(--whiteColor);

        &::before {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }
    &.active {
      span {
        color: var(--whiteColor);

        &::before {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
}

// Owl Carousel
.projects-slides {
.owl-theme {
  .owl-nav {
    margin-top: 0 !important;
    position: absolute;
    bottom: 23px;
    left: -140px;

    .owl-prev, .owl-next {
      margin: 0;
      padding: 0;
      z-index: 1;
      width: 50px;
      height: 50px;
      font-size: 30px;
      overflow: hidden;
      line-height: 50px;
      text-align: center;
      border-radius: 50%;
      position: relative;
      background: #140C2D;
      color: var(--whiteColor);
      transition: var(--transition);

      i {
        background: linear-gradient(90.99deg, #FDCE39 0.27%, #FD8963 30%, #ED5174 98.69%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &::before {
        top: 0;
        right: 0;
        width: 0;
        content: "";
        z-index: -1;
        height: 100%;
        position: absolute;
        border-radius: 50%;
        transition: var(--transition);
        background: linear-gradient(180deg, #FDCE39 0%, #FD8963 30.21%, #ED5174 100%);
      }
      &:hover {
        &::before {
          right: auto;
          width: 100%;
          left: 0;
        }
        i {
          background: linear-gradient(90.99deg, #ffffff 0.27%, #ffffff 30%, #ffffff 98.69%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .owl-next {
      margin-left: 10px;
    }
  }
}
}
.roadmap-slides {
.owl-theme {
  .owl-nav {
    left: 0;
    right: 0;
    bottom: 17px;
    max-width: 1350px;
    position: absolute;
    margin: {
      top: 0;
      left: auto;
      right: auto;
    };
    [class*=owl-] {
      left: 0;
      margin: 0;
      padding: 0;
      color: #fd8963;
      position: absolute;
      text-transform: uppercase;
      background-color: transparent;
      font: {
        size: var(--fontSize);
        weight: 600;
      };
      i {
        transform: translateY(-50%);
        position: absolute;
        font-size: 25px;
        top: 50%;
      }
      &.owl-prev {
        padding-left: 18px;
        left: 27px;

        i {
          left: -8px;
        }
      }
      &.owl-next {
        padding-right: 18px;
        left: auto;
        right: 0;

        i {
          right: -8px;
        }
      }
    }
  }
  .owl-dots {
    display: flex;
    line-height: 1;
    max-width: 1140px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.08);
    margin: {
      left: auto;
      right: auto;
      top: 30px !important;
    };
    .owl-dot {
      flex: 1;

      span {
        margin: 0;
        width: 100%;
        background: unset;
        transition: var(--transition);
      }
      &.active {
        span {
          background: linear-gradient(90deg, #FDCE39 0.23%, #FD8963 53.01%, #ED5174 100%);
        }
      }
    }
  }
}
}
.completed-projects-slides {
.owl-theme {
  .owl-nav {
    margin-top: 0 !important;

    .owl-prev, .owl-next {
      top: 50%;
      margin: 0;
      padding: 0;
      left: -70px;
      width: 50px;
      height: 50px;
      font-size: 30px;
      overflow: hidden;
      line-height: 50px;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      color: var(--whiteColor);
      transform: translateY(-50%);
      transition: var(--transition);
      background-color: rgba(255, 255, 255, 0.06);

      i {
        background: linear-gradient(90.99deg, #FDCE39 0.27%, #FD8963 30%, #ED5174 98.69%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
      &::before {
        top: 0;
        right: 0;
        width: 0;
        content: "";
        z-index: -1;
        height: 100%;
        position: absolute;
        border-radius: 50%;
        transition: var(--transition);
        background: linear-gradient(180deg, #FDCE39 0%, #FD8963 30.21%, #ED5174 100%);
      }
      &:hover {
        &::before {
          left: 0;
          right: auto;
          width: 100%;
        }
        i {
          background: linear-gradient(90.99deg, #ffffff 0.27%, #ffffff 30%, #ffffff 98.69%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
    }
    .owl-next {
      left: auto;
      right: -70px;
    }
  }
}
}
.projects-slider {
.owl-theme {
  .owl-nav {
    margin-top: 40px !important;

    .owl-prev, .owl-next {
      padding: 0;
      z-index: 1;
      width: 60px;
      height: 60px;
      font-size: 20px;
      overflow: hidden;
      line-height: 60px;
      text-align: center;
      border-radius: 50%;
      position: relative;
      background: #1A1135;
      color: var(--whiteColor);
      transition: var(--transition);
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
      margin: {
        top: 0;
        left: 5px;
        bottom: 0;
        right: 5px;
      };
      i {
        background: linear-gradient(90.99deg, #FDCE39 0.27%, #FD8963 30%, #ED5174 98.69%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        display: inline-block;
        font-size: 30px;
      }
      &::before {
        top: 0;
        right: 0;
        width: 0;
        content: "";
        z-index: -1;
        height: 100%;
        position: absolute;
        border-radius: 50%;
        transition: var(--transition);
        background: linear-gradient(180deg, #FDCE39 0%, #FD8963 30.21%, #ED5174 100%);
      }
      &:hover {
        &::before {
          right: auto;
          width: 100%;
          left: 0;
        }
        i {
          background: linear-gradient(90.99deg, #ffffff 0.27%, #ffffff 30%, #ffffff 98.69%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          display: inline-block;
        }
      }
    }
  }
}
}

// Video Popup
.video-popup {
  .overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9991;
      position: fixed;
      background-color: rgba(0, 0, 0, 0.8);
  
      iframe {
          width: 850px;
          height: 474px;
      }
      .nsm-content {
          top: 50%;
          left: 50%;
          width: 850px;
          height: 474px;
          position: absolute;
          transform: translate(-50%, -50%);
          background-color: var(--whiteColor);
      }
  }
  .nsm-dialog-btn-close {
      background-color: transparent;
      color: var(--whiteColor);
      position: absolute;
      border: none;
      right: -30px;
      top: -30px;
  }
}

// Page Title
.page-banner-area {
z-index: 1;
position: relative;
background: {
  position: center center;
  repeat: no-repeat;
  size: cover;
};
padding: {
  top: 150px;
  bottom: 150px;
};
&::before {
  top: 0;
  left: 0;
  right: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(20, 12, 45, 0.2);
}
.shape {
  position: absolute;
  z-index: -1;

  &.shape-1 {
    top: 0;
    left: 0;
  }
  &.shape-2 {
    top: 0;
    right: 0;
    max-width: 500px;
  }
  &.shape-5 {
    top: 0;
    right: 0;
    opacity: 0.8;
  }
}
}
.page-banner-content {
position: relative;
text-align: center;
max-width: 860px;
z-index: 1;
margin: {
  left: auto;
  right: auto;
};
ul {
  padding-left: 0;
  margin-bottom: 15px;
  list-style-type: none;

  li {
    display: inline-block;
    position: relative;
    font: {
      weight: 600;
      family: var(--headingFontFamily);
    };
    margin: {
      left: 18px;
      right: 18px;
    };
    &::before {
      top: -3px;
      width: 1px;
      right: -11px;
      height: 100%;
      content: "\EA6E";
      position: absolute;
      font: {
        size: 20px;
        family: remixicon !important;
      };
    }
    &:last-child {
      &::before {
        display: none;
      }
    }
    span {
      background: linear-gradient(91.21deg, #FDCE39 45.86%, #FD8963 72.71%, #ED5174 98.93%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
}
h2 {
  font-size: 60px;
  margin-bottom: 0;
}
.star {
  animation: rotation 30s infinite linear;
  position: absolute;
  top: -50px;
  left: 0;
}
}

// keyframes
@keyframes off-on {
0% {
  opacity: 0;
}
75% {
    opacity: 1;
}
100% {
    opacity: 0;
}
}
@keyframes rotation {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(359deg);
}
}
@keyframes ripple {
0%, 35% {
  transform: scale(0);
    opacity: 1;
}
50% {
  transform: scale(1.5);
    opacity: 0.8;
}
100% {
    opacity: 0;
  transform: scale(2);
}
}

/* Max width 767px */
@media only screen and (max-width: 767px) {

  body {
      font-size: 13px;
  }
  p {
      font-size: 13px;
  }
  .ptb-120 {
      padding: {
          top: 60px;
          bottom: 60px;
      };
  }
  .pt-120 {
      padding-top: 60px;
  }
  .pb-120 {
      padding-bottom: 60px;
  }
  .ptb-90 {
      padding: {
          top: 30px;
          bottom: 30px;
      };
  }
  .pt-90 {
      padding-top: 30px;
  }
  .pb-90 {
      padding-bottom: 30px;
  }
.read-more-btn {
  margin-top: 10px;

  .read-more {
    font-size: 15px;
  }
}

  // Default Btn
  .default-btn {
      padding: 12px 25px;
      font-size: 13px;
  }

// Section Title
.section-title {
  margin: 0 auto 35px;
  max-width: 100%;

  .top-title {
    margin-bottom: 10px;
  }
  h2 {
    font-size: 28px;
  }
  p {
    max-width: 100%;
    margin-top: 10px;
  }
  &.text-start {
    text-align: center !important;
    
    .default-btn {
      bottom: 0;
      margin-top: 17px;
      position: relative;
    }
  }
}

// How It Works Tabs
.how-it-works-tabs {
  .nav-tabset {
    margin-bottom: 20px;

    .nav-tab {
      margin: {
        left: 5px;
        right: 5px;
        bottom: 10px;
      };
      span {
        padding: 13px 20px;
        border-radius: 5px;
        font-size: 15px;

        &::after {
          margin: 1px;
          border-radius: 5px;
        }
        &::before {
          border-radius: 5px;
        }
      }
    }
  }
}

// Ecosystem Tabs
.ecosystem-tabs {
  .nav-tabset {
    margin-bottom: 12px;

    .nav-tab {
      margin: {
        right: 5px;
        bottom: 12px;
      };
      span {
        padding: 12px 18px;
        font-size: 15px;

        &::after {
          margin: 1px;
        }
      }
    }
  }
}

// Owl Carousel
.projects-slides {
  .owl-theme {
    .owl-nav {
      margin-bottom: 30px;
      position: relative;
      bottom: 0;
      left: 0;

      .owl-prev, .owl-next {
        width: 40px;
        height: 40px;
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}
.roadmap-slides {
  .owl-theme {
    .owl-nav {
      bottom: 0;
      max-width: 100%;
      position: relative;

      [class*=owl-] {
        font-size: 13px;
        position: relative;
        margin: {
          left: 10px;
          right: 10px;
        };
        i {
          font-size: 22px;
        }
        &.owl-prev {
          padding-left: 17px;
          left: 0;

          i {
            left: -5px;
          }
        }
        &.owl-next {
          padding-right: 17px;

          i {
            right: -5px;
          }
        }
      }
    }
    .owl-dots {
      display: none;
    }
  }
}
.completed-projects-slides {
  .owl-theme {
    .owl-nav {
      margin-bottom: 30px;

      .owl-prev, .owl-next {
        left: 0;
        top: auto;
        width: 40px;
        height: 40px;
        margin: 0 5px;
        font-size: 25px;
        line-height: 40px;
        position: relative;
        transform: translateY(0);
      }
      .owl-next {
        left: 0;
        right: 0;
      }
    }
  }
}
.projects-slider {
  .owl-theme {
    .owl-nav {
      margin-top: 25px !important;

      .owl-prev, .owl-next {
        width: 40px;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
      }
    }
  }
}

// Video Popup
.video-popup {
      .overlay {
          iframe {
              width: 290px;
              height: 164px;
          }
          .nsm-content {
              width: 290px;
              height: 164px;
          }
      }
      .nsm-dialog-btn-close {
          right: 0;
          top: -35px;
      }
  }

// Page Title
.page-banner-area {
  padding: {
    top: 60px;
    bottom: 60px;
  };
  .shape {
    display: none;
  }
}
.page-banner-content {
  max-width: 100%;
  
  ul {
    margin-bottom: 12px;

    li {
      margin: {
        left: 12px;
        right: 12px;
      };
      &::before {
        top: -2px;
        right: -7px;
        font-size: 15px;
      }
    }
  }
  h2 {
    font-size: 32px;
  }
  .star {
    display: none;
  }
}

// Projects Tabs
.projects-tabs {
  .nav-tabset {
    margin-bottom: 30px;
    padding: 10px 10px 5px;

    .nav-tab {
      margin: {
        left: 7px;
        right: 7px;
        bottom: 5px;
      };
      span {
        padding: 0;
        background-color: transparent;
        
        &::before {
          display: none;
        }
      }
    }
  }
}
.projects-wrap-tabs {
  .nav-tabset {
    margin-bottom: 30px;
    padding: 10px 10px 5px;

    .nav-tab {
      margin: {
        left: 7px;
        right: 7px;
        bottom: 5px;
      };
      span {
        padding: 0;
        background-color: transparent;
        
        &::before {
          display: none;
        }
      }
    }
  }
}

}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {

  body {
      font-size: 14px;
  }
  p {
      font-size: 14px;
  }
  .ptb-120 {
      padding: {
          top: 80px;
          bottom: 80px;
      };
  }
  .pt-120 {
      padding-top: 80px;
  }
  .pb-120 {
      padding-bottom: 80px;
  }
  .ptb-90 {
      padding: {
          top: 50px;
          bottom: 50px;
      };
  }
  .pt-90 {
      padding-top: 50px;
  }
  .pb-90 {
      padding-bottom: 50px;
  }
.read-more-btn {
  margin-top: 20px;

  .read-more {
    font-size: 17px;
  }
}

  // Default Btn
  .default-btn {
      padding: 13px 30px;
      font-size: 14px;
  }

// Section Title
.section-title {
  margin: 0 auto 40px;
  max-width: 100%;

  .top-title {
    margin-bottom: 10px;
  }
  h2 {
    font-size: 40px;
  }
  p {
    margin-top: 10px;
  }
}

// How It Works Tabs
.how-it-works-tabs {
  .nav-tabset {
    margin-bottom: 40px;

    .nav-tab {
      span {
        padding: 18px 45px;
          font-size: 16px;
      }
    }
  }
}

// Ecosystem Tabs
.ecosystem-tabs {
  .nav-tabset {
    margin-bottom: 30px;

    .nav-tab {
      span {
          font-size: 16px;
      }
    }
  }
}

// Owl Carousel
.projects-slides {
  .owl-theme {
    .owl-nav {
      margin-bottom: 30px;
      position: relative;
      bottom: 0;
      left: 0;
    }
  }
}
.roadmap-slides {
  .owl-theme {
    .owl-nav {
      bottom: 15px;
      max-width: 100%;
      
      [class*=owl-] {
        font-size: 14px;

        i {
          font-size: 22px;
          margin-top: -1px;
        }
        &.owl-prev {
          padding-left: 18px;
          left: 0;

          i {
            left: -5px;
          }
        }
        &.owl-next {
          padding-right: 18px;

          i {
            right: -5px;
          }
        }
      }
    }
    .owl-dots {
      max-width: 100%;
      margin: {
        left: 70px;
        right: 90px;
      };
    }
  }
}
.completed-projects-slides {
  .owl-theme {
    .owl-nav {
      margin-bottom: 30px;

      .owl-prev, .owl-next {
        left: 0;
        top: auto;
        margin: 0 5px;
        font-size: 25px;
        position: relative;
        transform: translateY(0);
      }
      .owl-next {
        left: 0;
        right: 0;
      }
    }
  }
}
.projects-slider {
  .owl-theme {
    .owl-nav {
      margin-top: 30px !important;

      .owl-prev, .owl-next {
        width: 50px;
        height: 50px;
        font-size: 20px;
        line-height: 50px;
      }
    }
  }
}

// Video Popup
.video-popup {
      .overlay {
          iframe {
              width: 650px;
              height: 366px;
          }
          .nsm-content {
              width: 650px;
              height: 366px;
          }
      }
  }

// Page Title
.page-banner-area {
  padding: {
    top: 80px;
    bottom: 80px;
  };
  .shape {
    display: none;
  }
}
.page-banner-content {
  max-width: 100%;
  
  ul {
    margin-bottom: 12px;

    li {
      margin: {
        left: 14px;
        right: 14px;
      };
      &::before {
        right: -8px;
        font-size: 17px;
      }
    }
  }
  h2 {
    font-size: 45px;
  }
}

// Projects Tabs
.projects-tabs {
  .nav-tabset {
    margin-bottom: 40px;

    .nav-tab {
      span {
        padding: 11px 10px;
      }
    }
  }
}
.projects-wrap-tabs {
  .nav-tabset {
    margin-bottom: 30px;

    .nav-tab {
      margin: {
        left: 4px;
        right: 4px;
      };
      span {
        padding: 8px;
      }
    }
  }
}

}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

  body {
      font-size: 15px;
  }
  p {
      font-size: 15px;
  }

  // Default Btn
  .default-btn {
      padding: 14px 30px;
      font-size: 15px;
  }

// Section Title
.section-title {
  margin: 0 auto 45px;

  .top-title {
    margin-bottom: 10px;
  }
  h2 {
    font-size: 48px;
  }
}

// How It Works Tabs
.how-it-works-tabs {
  .nav-tabset {
    margin-bottom: 40px;

    .nav-tab {
      span {
        padding: 22px 70px;
        font-size: 18px;
      }
    }
  }
}

// Owl Carousel
.projects-slides {
  .owl-theme {
    .owl-nav {
      margin-bottom: 30px;
      position: relative;
      bottom: 0;
      left: 0;
    }
  }
}
.roadmap-slides {
  .owl-theme {
    .owl-nav {
      bottom: 16px;
      max-width: 100%;
      
      [class*=owl-] {
        font-size: 15px;

        i {
          font-size: 25px;
        }
        &.owl-prev {
          padding-left: 18px;
          left: 0;
        }
        &.owl-next {
          padding-right: 18px;
        }
      }
    }
    .owl-dots {
      max-width: 100%;
      margin: {
        left: 70px;
        right: 90px;
      };
    }
  }
}
.completed-projects-slides {
  .owl-theme {
    .owl-nav {
      margin-bottom: 30px;

      .owl-prev, .owl-next {
        left: 0;
        top: auto;
        margin: 0 5px;
        font-size: 25px;
        position: relative;
        transform: translateY(0);
      }
      .owl-next {
        left: 0;
        right: 0;
      }
    }
  }
}
.projects-slider {
  .owl-theme {
    .owl-nav {
      margin-top: 30px !important;

      .owl-prev, .owl-next {
        width: 50px;
        height: 50px;
        font-size: 20px;
        line-height: 50px;
      }
    }
  }
}

// Ecosystem Tabs
.ecosystem-tabs {
  .nav-tabset {
    margin-bottom: 40px;

    .nav-tab {
      span {
        font-size: 16px;
        padding: 13px 19px;
      }
    }
  }
}

// Page Title
.page-banner-area {
  padding: {
    top: 100px;
    bottom: 100px;
  };
}
.page-banner-content {
  max-width: 860px;
  
  ul {
    li {
      &::before {
        top: -4px;
      }
    }
  }
  h2 {
    font-size: 50px;
  }
}

}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {

body {
  background-color: #1a1135;
}
// Owl Carousel
.roadmap-slides {
  .owl-theme {
    .owl-nav {
      max-width: 1175px;
    }
    .owl-dots {
      max-width: 985px;
    }
  }
}
.completed-projects-slides {
  .owl-theme {
    .owl-nav {
      .owl-prev, .owl-next {
        left: -35px;
        width: 40px;
        height: 40px;
        font-size: 25px;
        line-height: 40px;
      }
      .owl-next {
        left: auto;
        right: -35px;
      }
    }
  }
}

// Ecosystem Tabs
.ecosystem-tabs {
  .nav-tabset {
    .nav-tab {
      span {
        font-size: 18px;
      }
    }
  }
}

}

/* Min width 1400px to Max width 1499px */
@media only screen and (min-width: 1400px) and (max-width: 1499px) {

  body{
    background-color: #1a1135;
  }
// Owl Carousel
.completed-projects-slides {
  .owl-theme {
    .owl-nav {
      .owl-prev, .owl-next {
        left: -47px;
        width: 40px;
        height: 40px;
        font-size: 25px;
        line-height: 40px;
      }
      .owl-next {
        left: auto;
        right: -47px;
      }
    }
  }
}

}

/* Min width 1600px */
@media only screen and (min-width: 1600px) {
  
  body{
    background-color: #1a1135;
  }
}